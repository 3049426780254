import { useSubscription, gql } from "@apollo/client";
import React from "react";
import PassGrid from "./PassGrid";
import CapacityTracker from "./CapacityTracker";
import { Spinner } from "@chakra-ui/react";

const FETCH_ROOM_INBOUND_PASSES = gql`
  query GetRoomInboundPasses($id: uuid! = "") {
    rooms_by_pk(id: $id) {
      inbound_passes {
        request_approved
        destination_room {
          name
          pinnable {
            color
            id
            emoji
          }
        }
        start_time
        expiration_time
        end_time
        travel_type
        issuing_user {
          full_name
          id
        }
        receipient_user {
          id
          full_name
        }
      }
      outbound_passes {
        request_approved
        destination_room {
          name
          pinnable {
            color
            id
            emoji
          }
        }
        start_time
        expiration_time
        end_time
        travel_type
        issuing_user {
          full_name
          id
        }
        receipient_user {
          id
          full_name
        }
      }
    }
  }
`;

const RoomViewer = ({ roomData }: { roomData: any }) => {
  const { loading, error, data: roomInboundPassResponse } = useSubscription(
    FETCH_ROOM_INBOUND_PASSES,
    {
      variables: {
        id: roomData.id,
      },
    }
  );

  const roomResponseData = roomInboundPassResponse?.rooms_by_pk;

  return (
    <>
      <p className="text-sm font-medium text-gray-600">Room Viewer</p>
      <h1 className="text-xl font-bold text-gray-900 sm:text-2xl">
        {roomData?.name}
      </h1>

      {loading && <Spinner />}
      {error && <p>{JSON.stringify(error)}</p>}

      <div className="mt-2">
        <CapacityTracker roomId={roomData?.id} />
      </div>

      <p className="text-sm font-medium text-gray-600 pt-4">
        Past Inbound Passes
      </p>

      <PassGrid
        showStudentName
        mode="pastPasses"
        customData={roomResponseData?.inbound_passes}
      />
      <p className="text-sm font-medium text-gray-600 pt-4">
        Past Outbound Passes
      </p>

      <PassGrid
        showStudentName
        mode="pastPasses"
        customData={roomResponseData?.outbound_passes}
      />
    </>
  );
};
export default RoomViewer;
