import { gql, useSubscription } from "@apollo/client";
import { Stack, Skeleton, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTypedSelector } from "../App/rootReducer";

import PassCard from "./PassCard";

const FETCH_USER_PASSES = gql`
  subscription GetUserPasses(
    $user_id: uuid! = ""
    $school_id: uuid = ""
    $request_approved: Boolean = true
    $greater_than_end_time: timestamptz = ""
  ) {
    users_by_pk(id: $user_id) {
      issued_passes(
        where: {
          _or: [
            {
              school_id: { _eq: $school_id }
              request_approved: { _eq: $request_approved }
              end_time: { _gte: $greater_than_end_time }
            }
            {
              school_id: { _eq: $school_id }
              request_approved: { _eq: $request_approved }
              end_time: { _is_null: true }
            }
          ]
        }
      ) {
        request_approved
        destination_room {
          name
          pinnable {
            color
            id
            emoji
          }
        }
        start_time
        expiration_time
        end_time
        travel_type

        issuing_user {
          full_name
          id
        }
        receipient_user {
          id
          full_name
        }
      }
    }
  }
`;

// Mode is either "requests", "activePasses", or "pastPasses"
const PassGrid = ({
  handlePinnableSelect,
  mode,
  customData,
  showStudentName = true,
}: {
  handlePinnableSelect?: any;
  mode: string;
  customData?: any;
  showStudentName?: boolean;
}) => {
  // eslint-disable-next-line
  const [currentDate, setCurrentDate] = useState(new Date());

  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );

  const currentUserUid = useTypedSelector((state) => state.auth.uid);

  const { loading, error, data: passesQueryResponse } = useSubscription(
    FETCH_USER_PASSES,
    {
      variables: {
        school_id: currentSchool?.id,
        user_id: currentUserUid,
        request_approved: mode === "requests" ? false : true,
        greater_than_end_time: currentDate.toISOString(),
      },
    }
  );

  if (loading)
    return (
      <>
        <Stack>
          <Skeleton height="80px" />
          <Skeleton height="80px" />
        </Stack>
      </>
    );

  if (error) return <h1>Error: {error.message}</h1>;

  const passesData =
    customData || passesQueryResponse?.users_by_pk?.issued_passes;

  return (
    <>
      <Flex
        className="mt-2"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-start"
      >
        {(passesData?.length === 0 || !passesData) && (
          <div className="bg-gray-50 overflow-hidden rounded-lg w-full border-4 border-dotted">
            <div className="px-4 py-5 sm:p-6 text-gray-800">
              <h4>
                {mode === "pastPasses"
                  ? "No past passes to display... Try creating one!"
                  : "No active hall passes to display... Try creating one!"}
              </h4>
            </div>
          </div>
        )}
        {/* Card */}
        {passesData?.map((passData: any) => (
          <PassCard
            handleOnClick={handlePinnableSelect}
            passData={passData}
            showWhenEnded={mode === "pastPasses"}
            showStudentName={showStudentName}
          />
        ))}
      </Flex>
    </>
  );
};

export default PassGrid;
