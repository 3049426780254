import { motion } from "framer-motion";
import React from "react";
import passColorMapping from "../utils/passColorMapping";
import { Text, Flex } from "@chakra-ui/react";
import { Emoji } from "emoji-mart";
import Timer from "./Timer";
const PassCard = ({
  passData,
  handleOnClick,
  showWhenEnded = false,
  showStudentName = true,
}: {
  passData: any;
  handleOnClick: any;
  showWhenEnded?: boolean;
  showStudentName?: boolean;
}) => {
  const [activeStatus, setActiveStatus] = React.useState(true);

  const { expiration_time: expirationTime, end_time: endTime } = passData;
  const { name: roomName } = passData?.destination_room;
  const { color, emoji } = passData?.destination_room?.pinnable;

  if (!activeStatus && endTime && !showWhenEnded) {
    // The existence of an end time indicates that the pass is supposed to expire at that time
    return null;
  }

  const expirationDateObject = new Date(expirationTime);

  return (
    <motion.button
      onClick={() => handleOnClick(passData)}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.95 }}
      className="bg-white overflow-hidden shadow rounded-lg"
      style={{
        maxWidth: "140px",
        minWidth: "140px",
        minHeight: "90px",
        maxHeight: "90px",
        margin: "5px",
        backgroundImage: passColorMapping[color].radialGradient,
      }}
    >
      <Flex direction="column" justify="space-between">
        {!showStudentName && <Emoji emoji={emoji} size={24} set="twitter" />}

        <Text
          color="white"
          fontSize="sm"
          fontWeight="semibold"
          className="leading-4"
        >
          {roomName}
        </Text>

        {showWhenEnded ? (
          <p className="text-white">
            {expirationDateObject.toLocaleTimeString()}
          </p>
        ) : (
          <Timer
            timerTextStyle={{
              color: "white",
              textAlign: "center",
              fontSize: 15,
              paddingBottom: 10,
            }}
            setActiveStatus={(status: any) => setActiveStatus(status)}
            targetTime={expirationDateObject}
          />
        )}

        {showStudentName && (
          <div className="bg-white bg-opacity-20 justify-center align-center">
            <Flex alignItems="center" justify="center">
              <Text fontWeight="semibold" fontSize="sm" color="white">
                {passData?.receipient_user?.full_name || "No name data"}
              </Text>
            </Flex>
          </div>
        )}
      </Flex>
    </motion.button>
  );
};

export default PassCard;
