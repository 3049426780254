import React from "react";

const hallPassRequests = undefined;

const HallPassRequests = () => {
  return (
    <section aria-labelledby="recent-hires-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="p-6">
          <h2
            className="text-base font-medium text-gray-900"
            id="recent-hires-title"
          >
            Hall Pass Requests
          </h2>

          {hallPassRequests ? (
            <>
              <div className="flow-root mt-6">
                <ul className="-my-5 divide-y divide-gray-200">
                  <li className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          Leonard Krasner
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          "Media Center 5" for 15m
                        </p>
                      </div>
                      <div>
                        <a
                          href="/"
                          className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Accept
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          Floyd Miles
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          "Media Center 5" for 15m
                        </p>
                      </div>
                      <div>
                        <a
                          href="/"
                          className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Accept
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          Emily Selman
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          "Media Center 5" for 15m
                        </p>
                      </div>
                      <div>
                        <a
                          href="/"
                          className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Accept
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                          alt=""
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          Kristin Watson
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          "Media Center 5" for 15m
                        </p>
                      </div>
                      <div>
                        <a
                          href="/"
                          className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Accept
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="mt-6">
                <a
                  href="/"
                  className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Accept all
                </a>
              </div>
            </>
          ) : (
            <div className="mt-6 bg-gray-50 overflow-hidden rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <p>You don't have any current hall pass requests!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HallPassRequests;
