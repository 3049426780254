import React from "react";
import { IonPage } from "@ionic/react";
import AccountsHeader from "./AccountsHeader";
import { gql, useSubscription } from "@apollo/client";
import { useTypedSelector } from "../../App/rootReducer";
import { Spinner } from "@chakra-ui/react";

const FETCH_SCHOOL_USERS = gql`
  query GetSchoolUsers($id: uuid! = "") {
    schools_by_pk(id: $id) {
      users {
        role
        user {
          full_name
        }
      }
    }
  }
`;

const UsersTable = ({ usersData }: { usersData: any }) => (
  <div className="flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Role
                </th>
                {/* <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Email
                </th> */}
                {/* <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Role
                </th> */}
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Odd row */}

              {usersData?.map((userResponse: any) => (
                <tr className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {userResponse?.user?.full_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {userResponse?.role}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    jane.cooper@example.com
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    Admin
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a
                      href="/"
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}

              {/* More items... */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

const HallMonitor: React.FC = () => {
  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );

  const { loading, error, data: usersQueryResponse } = useSubscription(
    FETCH_SCHOOL_USERS,
    {
      variables: {
        id: currentSchool?.id,
      },
    }
  );

  const usersData = usersQueryResponse?.schools_by_pk?.users;

  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle>Accounts</IonTitle>
        </IonToolbar>
      </IonHeader> */}

      <div className="h-screen flex overflow-hidden bg-gray-100">
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
          <AccountsHeader />

          <div className="mt-8">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                School Accounts
              </h2>
            </div>

            <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              {loading && <Spinner />}
              {error && <h1>{error.message}</h1>}

              <UsersTable usersData={usersData} />
            </div>
          </div>
        </main>
      </div>
    </IonPage>
  );
};

export default HallMonitor;
