import React from "react";
import { useTypedSelector } from "../../App/rootReducer";
import capitalize from "../../utils/capitalize";
import CreatePassModal from "./CreatePassModal";

const WelcomePanel = () => {
  const profileInfo = useTypedSelector((state) => state.auth.profile);

  return (
    <section aria-labelledby="profile-overview-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <h2 className="sr-only" id="profile-overview-title">
          Profile Overview
        </h2>
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                <img
                  className="mx-auto h-20 w-20 rounded-full"
                  src="https://www.martinschools.org/cms/lib/FL02202359/Centricity/Domain/9/IMS%20Logo.jpg"
                  alt=""
                />
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">
                  Welcome back,
                </p>
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                  {profileInfo.firstName} {profileInfo.lastName}
                </p>
                <p className="text-sm font-medium text-gray-600">
                  {capitalize(profileInfo.currentSchool?.role)} at{" "}
                  {profileInfo.currentSchool?.name}
                </p>
              </div>
            </div>
            <CreatePassModal />
          </div>
        </div>
        <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
          <div className="px-6 py-5 text-sm font-medium text-center">
            <span className="text-gray-900">0 </span>
            <span className="text-gray-600">Pending Pass Requests</span>
          </div>

          {/* <div className="px-6 py-5 text-sm font-medium text-center">
                          <span className="text-gray-900">4</span>
                          <span className="text-gray-600">Sick days left</span>
                        </div> */}

          <div className="px-6 py-5 text-sm font-medium text-center">
            <span className="text-gray-900">0 </span>
            <span className="text-gray-600">Unread Notifications</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomePanel;
