import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import passColorMapping from "../../utils/passColorMapping";
import "emoji-mart/css/emoji-mart.css";
import { gql, useMutation } from "@apollo/client";
import { useTypedSelector } from "../../App/rootReducer";
import toast from "react-hot-toast";

const CREATE_USER = gql`
  mutation CreateUser(
    $schoolID: String! = ""
    $userRole: String! = ""
    $password: String! = ""
    $lastName: String! = ""
    $firstName: String! = ""
    $email: String! = ""
  ) {
    createUser(
      email: $email
      lastName: $lastName
      password: $password
      schoolID: $schoolID
      userRole: $userRole
      firstName: $firstName
    ) {
      userUid
    }
  }
`;

const CreateAccountModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("student");

  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );

  const [
    createUser,
    { loading: createUserLoading, error: createUserError },
  ] = useMutation(CREATE_USER);

  const handleUserCreate = async () => {
    if (firstName === "") return toast.error("Please enter a first name.");
    if (lastName === "") return toast.error("Please enter a last name.");
    if (email === "") return toast.error("Please enter a valid email.");
    if (password.length < 6)
      return toast.error("Please enter a longer password.");

    try {
      await createUser({
        variables: {
          firstName,
          lastName,
          password,
          email,
          userRole,
          schoolID: currentSchool.id,
        },
      });

      toast.success(
        "Successfully created student: " + firstName + " " + lastName
      );
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        type="button"
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
      >
        Add Students
      </button>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent className="rounded-xl">
          <ModalHeader
            style={{
              backgroundImage: passColorMapping["red"].radialGradient,
              transition: "background-image .3s ease",
            }}
            className="text-white rounded-t-xl shadow-xl"
          >
            Create Account
          </ModalHeader>
          {/* <ModalCloseButton color="white" /> */}
          <ModalBody pt={8} pb={6}>
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      User Creation Wizard
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Manually add one user to your school here. In order to
                      bulk add users, please contact your assigned support
                      advisor.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div>
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 pb-6 bg-white sm:p-6">
                        <div className="px-4 sm:px-0">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            User Options
                          </h3>
                          {/* <p className="mt-1 text-sm text-gray-600">
                            Adjust 
                          </p> */}
                        </div>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first_name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              First Name
                            </label>
                            <input
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              type="text"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="room_number"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Last Name
                            </label>
                            <input
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              type="text"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-4">
                            <label
                              htmlFor="email_address"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email Address
                            </label>
                            <input
                              type="text"
                              name="email_address"
                              id="email_address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-4">
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-8 sm:col-span-3">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Role
                            </label>
                            <RadioGroup
                              onChange={setUserRole as any}
                              value={userRole}
                            >
                              <Stack direction="row">
                                <Radio value="student">Student</Radio>
                                <Radio value="teacher">Teacher</Radio>
                                <Radio value="admin">Admin</Radio>
                              </Stack>
                            </RadioGroup>
                            {/* <select
                              id="country"
                              name="country"
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option>United States</option>
                              <option>Canada</option>
                              <option>Mexico</option>
                            </select> */}
                          </div>

                          {/* <div className="col-span-4 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Time Limit
                            </label>

                            <NumberInput
                              step={5}
                              defaultValue={10}
                              min={1}
                              max={500}
                              onChange={(e) => setTimeLimit(parseInt(e))}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </div> */}

                          {/* <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                            <label
                              htmlFor="city"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Pass Limit (Leave blank for infinite)
                            </label>

                            <NumberInput
                              step={1}
                              defaultValue={10}
                              min={1}
                              max={500}
                              onChange={(e) => setPassLimit(parseInt(e))}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </div> */}

                          {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label
                              htmlFor="state"
                              className="block text-sm font-medium text-gray-700"
                            >
                              State / Province
                            </label>
                            <input
                              type="text"
                              name="state"
                              id="state"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label
                              htmlFor="postal_code"
                              className="block text-sm font-medium text-gray-700"
                            >
                              ZIP / Postal
                            </label>
                            <input
                              type="text"
                              name="postal_code"
                              id="postal_code"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Save
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1>{JSON.stringify(createUserError)}</h1>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleUserCreate}
              loading={createUserLoading}
            >
              Create User
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateAccountModal;
