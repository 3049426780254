import React from "react";
import { IonPage } from "@ionic/react";
import HallMonitorHeader from "./HallMonitorHeader";
import HallMonitorPassGrid from "../../components/HallMonitorPassGrid";

const HallMonitor: React.FC = () => {
  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle>Accounts</IonTitle>
        </IonToolbar>
      </IonHeader> */}

      <div className="h-screen flex overflow-hidden bg-gray-100">
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
          <HallMonitorHeader />

          <div className="mt-8">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Active Passes
              </h2>
            </div>

            <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              <HallMonitorPassGrid mode="activePasses" showStudentName />
            </div>
          </div>
        </main>
      </div>
    </IonPage>
  );
};

export default HallMonitor;
