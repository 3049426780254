import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import passColorMapping from "../../../utils/passColorMapping";
import "emoji-mart/css/emoji-mart.css";
import { gql, useMutation } from "@apollo/client";
import { useTypedSelector } from "../../../App/rootReducer";
import toast from "react-hot-toast";
import StudentSelector from "./StudentSelector";
import FromRoomSelector from "./FromRoomSelector";
import { Pinnable, Student } from "../../../types/school";
import PassOptionsAndDispatch from "./PassOptionsAndDispatch";

import "./modalStyles.css";
import { CSSTransition } from "react-transition-group";
import ErrorAlert from "../../../components/ErrorAlert";
import "../../../styles/additional.css";
import { motion } from "framer-motion";

const CREATE_PASS = gql`
  mutation CreatePass(
    $issuing_user_id: uuid = ""
    $receipient_user_id: uuid = ""
    $origin_room_id: uuid = ""
    $expiration_time: timestamptz = ""
    $end_time: timestamptz = ""
    $destination_room_id: uuid = ""
    $school_id: uuid = ""
    $start_time: timestamptz = ""
    $travel_type: String = ""
  ) {
    insert_passes_one(
      object: {
        issuing_user_id: $issuing_user_id
        receipient_user_id: $receipient_user_id
        origin_room_id: $origin_room_id
        request_approved: true
        expiration_time: $expiration_time
        end_time: $end_time
        destination_room_id: $destination_room_id
        school_id: $school_id
        start_time: $start_time
        travel_type: $travel_type
      }
    ) {
      id
    }
  }
`;

const CreatePassModal = () => {
  const schoolEndPassWhenExpire = true; // TODO: Actually implement this setting for schools later

  const [
    createPass,
    { loading: createPassLoading, error: createPassError },
  ] = useMutation(CREATE_PASS);

  // Selectors
  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );
  const currentUserUid = useTypedSelector((state) => state.auth.uid);

  // Modal Navigation Items
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Internal modal state
  const [selectedStudent, setSelectedStudent] = useState<Student>();

  // TODO: Replace pinnable selector with room
  const [
    selectedFromRoomPinnable,
    setSelectedFromRoomPinnable,
  ] = useState<Pinnable>();
  const [
    selectedToRoomPinnable,
    setSelectedToRoomPinnable,
  ] = useState<Pinnable>();
  const [travelType, setTravelType] = useState("round-trip"); // TODO: Actually implement trip types
  const [passDuration, setPassDuration] = useState(15);

  // Step handler functions
  const handleStudentSelect = (student: Student) => {
    setSelectedStudent(student);
    handleNext();
  };

  const handleFromRoomSelect = (pinnableData: Pinnable) => {
    setSelectedFromRoomPinnable(pinnableData);
    handleNext();
  };

  const handleToRoomSelect = (pinnableData: Pinnable) => {
    setSelectedToRoomPinnable(pinnableData);
    handleNext();
  };

  const handlePassDispatch = async () => {
    if (
      !selectedFromRoomPinnable ||
      !selectedToRoomPinnable ||
      !selectedStudent
    )
      return toast.error("Invalid data in pass creation. Please try again.");

    // Time logic
    const startTime = new Date();
    const expirationTime = new Date(
      startTime.getTime() + passDuration * 60 * 1000
    );
    let endTime = null;
    if (schoolEndPassWhenExpire) {
      endTime = expirationTime;
    }

    // Extract GraphQL Query Variables
    const fromRoomId = selectedFromRoomPinnable.rooms[0].id;
    const toRoomId = selectedToRoomPinnable.rooms[0].id;
    const receipientUserId = selectedStudent.id;

    // TODO: Constraints (permissions, room capacity, etc.)

    // Perform Query
    try {
      await createPass({
        variables: {
          issuing_user_id: currentUserUid,
          receipient_user_id: receipientUserId,
          origin_room_id: fromRoomId,
          expiration_time: expirationTime.toISOString(),
          end_time: endTime ? endTime.toISOString() : null,
          destination_room_id: toRoomId,
          school_id: currentSchool.id,
          start_time: startTime.toISOString(),
          travel_type: travelType,
        },
      });

      toast.success(
        "Successfully created pass for: " + selectedStudent.fullName
      );
      onClose();
    } catch (e) {
      toast.error(e);
    }
  };

  // Modal step contents
  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return {
          title: "Select student(s)",
          component: (
            <StudentSelector
              key="studentselector"
              handleStudentSelect={handleStudentSelect}
            />
          ),
        };
      case 1:
        return {
          title: "Where from?",
          component: (
            <FromRoomSelector
              key="fromselector"
              handleFromRoomSelect={handleFromRoomSelect}
            />
          ),
        };
      case 2:
        return {
          title: "Where to?",
          component: (
            <FromRoomSelector
              key="toselector"
              handleFromRoomSelect={handleToRoomSelect}
            />
          ),
        };

      case 3:
        return {
          title: "Review details and dispatch",
          component: (
            <PassOptionsAndDispatch
              travelType={travelType}
              setTravelType={setTravelType}
              setPassDuration={setPassDuration}
              passDuration={passDuration}
              selectedStudent={selectedStudent}
              selectedToRoomPinnable={selectedToRoomPinnable}
              handlePassDispatch={handlePassDispatch}
            />
          ),
        };
      default:
        return {
          title: "Invalid step.",
          component: <h1>Something went wrong.</h1>,
        };
    }
  }

  return (
    <>
      {/* Modal Button */}
      <div className="mt-5 flex justify-center sm:mt-0">
        <motion.button
          onClick={onOpen}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.95 }}
          style={{ background: passColorMapping["pink"].radialGradient }}
          className="pulse flex justify-center items-center px-8 py-6 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white  hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-pink-500"
        >
          Create Pass
        </motion.button>
      </div>

      {/* Actual Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent className="rounded-t-xl">
          <ModalHeader
            style={{
              // backgroundImage: passColorMapping["lightBlue"].radialGradient,
              transition: "background-image .3s ease",
            }}
            className=" rounded-t-xl shadow-md"
          >
            <Button disabled={activeStep === 0} onClick={handleBack} mr="5">
              Back
            </Button>
            {getStepContent(activeStep)?.title}
            {createPassLoading && <Spinner />}
          </ModalHeader>
          <ModalBody pt={8} pb={6}>
            <ErrorAlert
              message={createPassError?.name}
              details={createPassError?.message}
            />
            <CSSTransition
              key={JSON.stringify(activeStep)}
              classNames="fade"
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
            >
              {getStepContent(activeStep).component}
            </CSSTransition>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleNext}>
              Next
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreatePassModal;
