import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

export interface profileSchool {
  id: string | null;
  name: string | null;
  // role: string | null;
}

export interface CoreAuthenticationState {
  status: string | null;
  token: string | null;
  uid: string | null;
}

export interface AuthenticationState extends CoreAuthenticationState {
  retrievedProfile: boolean;
  profile: AuthenticationProfile;
}

export interface profileSchoolWithRole extends profileSchool {
  role: string | null;
}
export interface AuthenticationProfile {
  firstName: string | null;
  lastName: string | null;
  permissions: string[];
  schools: profileSchoolWithRole[];
  currentSchool: profileSchoolWithRole;
}

const initialState: AuthenticationState = {
  profile: {
    firstName: null,
    lastName: null,
    permissions: [],
    schools: [],
    currentSchool: { id: null, name: null, role: null },
  },
  status: "loading", // Either "loading" or "in"
  retrievedProfile: false,
  token: null,
  uid: null,
};

export const signedIn = createAction<CoreAuthenticationState>("auth/signedIn");
export const setProfileData = createAction<AuthenticationProfile>(
  "auth/profileData"
);
export const updateToken = createAction<string>("auth/updateToken");
export const signedOut = createAction("auth/signedOut");

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      signedIn,
      (
        state: AuthenticationState,
        action: PayloadAction<CoreAuthenticationState>
      ) => {
        state.status = action.payload.status;
        state.token = action.payload.token;
        state.uid = action.payload.uid;
      }
    );

    builder.addCase(
      updateToken,
      (state: AuthenticationState, action: PayloadAction<string>) => {
        state.token = action.payload;
      }
    );

    builder.addCase(
      setProfileData,
      (
        state: AuthenticationState,
        action: PayloadAction<AuthenticationProfile>
      ) => {
        state.retrievedProfile = true;
        state.profile.permissions = action.payload.permissions;
        state.profile.firstName = action.payload.firstName;
        state.profile.lastName = action.payload.lastName;
        state.profile.schools = action.payload.schools;
        state.profile.currentSchool = action.payload.currentSchool;
      }
    );

    builder.addCase(
      signedOut,
      (state: AuthenticationState, action: PayloadAction) => {
        // Sets state to initial state
        Object.assign(state, initialState);
      }
    );
  },
});

export default authSlice.reducer;
