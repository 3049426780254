import { gql, useSubscription } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import React, { useState } from "react";

const GET_ROOM_CAPACITY_INFO = gql`
  query GetCapacityTrackingInformation(
    $id: uuid! = ""
    $greater_than_end_time: timestamptz = ""
  ) {
    rooms_by_pk(id: $id) {
      max_passes_to
      inbound_passes_aggregate(
        where: {
          request_approved: { _eq: true }
          end_time: { _gte: $greater_than_end_time }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

const CapacityTracker = ({ roomId }: { roomId: string }) => {
  const [currentDate, setCurrentDate] = useState(new Date()); // eslint-disable-line

  const { loading, error, data: capacityQueryResponse } = useSubscription(
    GET_ROOM_CAPACITY_INFO,
    {
      variables: {
        id: roomId,
        greater_than_end_time: currentDate.toISOString(),
      },
    }
  );

  const capacityData = capacityQueryResponse?.rooms_by_pk;

  const currentPassCount =
    capacityData?.inbound_passes_aggregate?.aggregate?.count;
  const maxPassesTo = capacityData?.max_passes_to;

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <dt className="text-sm font-medium text-gray-500 truncate">
          Capacity Tracker
        </dt>
        <div className="flex flex-row items-end">
          {loading && <Spinner />}
          {error && <h4>{error.message}</h4>}

          <p className="mt-1 text-3xl font-semibold text-gray-900">
            {currentPassCount}
          </p>
          <p>/{maxPassesTo} students in room</p>
        </div>
      </div>
    </div>
  );
};

export default CapacityTracker;
