import { useSubscription, gql } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import React from "react";
import PassGrid from "./PassGrid";

const FETCH_STUDENT_INFO = gql`
  query GetStudentInfo($id: uuid! = "") {
    users_by_pk(id: $id) {
      full_name
      received_passes {
        request_approved
        destination_room {
          name
          pinnable {
            color
            id
            emoji
          }
        }
        start_time
        expiration_time
        end_time
        travel_type
      }
    }
  }
`;

const StudentViewer = ({ studentData }: { studentData: any }) => {
  const { loading, error, data: studentInfoResponse } = useSubscription(
    FETCH_STUDENT_INFO,
    {
      variables: {
        id: studentData.id,
      },
    }
  );

  const studentResponseData = studentInfoResponse?.users_by_pk;

  return (
    <>
      <p className="text-sm font-medium text-gray-600">Student Viewer</p>
      <h1 className="text-xl font-bold text-gray-900 sm:text-2xl">
        {studentData?.fullName}
      </h1>

      {loading && <Spinner />}
      {error && <p>{JSON.stringify(error)}</p>}

      <p className="text-sm font-medium text-gray-600 pt-4">Past Passes</p>

      {/* {JSON.stringify(studentInfoResponse)} */}
      <PassGrid
        mode="pastPasses"
        customData={studentResponseData?.received_passes}
      />
    </>
  );
};
export default StudentViewer;
