import { IonRouterLink } from "@ionic/react";
import React, { useState } from "react";
import { MonocolorLogoOnly, WhiteLogoOnly } from "../../config/images";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { auth } from "../../App/AppAuthentication";
import history from "../../utils/history";
import { useTypedSelector } from "../../App/rootReducer";
import { getCoreScreens } from "../../config/navConfig";
import SearchModal from "../../components/SearchModal";

const handleSignOut = () => {
  toast.promise(auth.signOut(), {
    loading: "Signing out...",
    success: <b>Signed out!</b>,
    error: (
      <b>Something went wrong when trying to sign out. Please try again.</b>
    ),
  });

  history.push("/login");
};

// Desktop Navigation Bar, only shows up on desktop
const DesktopNavigationBar = () => {
  const userPermissions = useTypedSelector(
    (state) => state.auth.profile.permissions
  );
  return (
    <>
      <div className="hidden lg:block lg:col-span-2">
        <nav className="flex space-x-4">
          {getCoreScreens(userPermissions).map((navItem) => (
            <Link
              key={navItem.path}
              to={navItem.path}
              className="text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
              aria-current="page"
            >
              {navItem.title}
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
};

const NavigationBar = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
        <DesktopNavigationBar />

        <div className="px-12 lg:px-0">
          <div className="max-w-xs mx-auto w-full lg:max-w-md">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative text-white focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <SearchModal
                isOpen={isSearchOpen}
                onClose={() => setIsSearchOpen(false)}
              />
              <input
                id="search"
                onClick={() => setIsSearchOpen(true)}
                className="block w-full text-white bg-white bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
                placeholder="Search"
                type="search"
                name="search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  const MobileMenuOpenButton = () => (
    <button
      className="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
      aria-expanded="false"
      onClick={() => setDropdownMenuOpen(true)}
    >
      <span className="sr-only">Open main menu</span>

      <svg
        className="block h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>

      <svg
        className="hidden h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );

  return (
    <header className="pb-24 bg-gradient-to-r from-blue-500 to-light-blue-700">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
          <TopNavBarLeft />

          <TopNavBarRight
            dropdownMenuOpen={dropdownMenuOpen}
            setDropdownMenuOpen={setDropdownMenuOpen}
          />

          <NavigationBar />

          <div className="absolute right-0 flex-shrink-0 lg:hidden">
            <MobileMenuOpenButton />
          </div>
        </div>
      </div>

      <MobileMenu
        dropdownMenuOpen={dropdownMenuOpen}
        setDropdownMenuOpen={setDropdownMenuOpen}
      />
    </header>
  );
};

export default Header;

const MobileMenu = ({ dropdownMenuOpen, setDropdownMenuOpen }: any) => {
  const userPermissions = useTypedSelector(
    (state) => state.auth.profile.permissions
  );

  return (
    <>
      <Transition
        show={dropdownMenuOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-150 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="z-20 fixed inset-0 bg-black bg-opacity-25 lg:hidden"
          aria-hidden="true"
        ></div>
      </Transition>

      <Transition
        show={dropdownMenuOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-150 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top lg:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
            <div className="pt-3 pb-2">
              <div className="flex items-center justify-between px-4">
                <div>
                  <img
                    className="h-12 w-auto"
                    src={MonocolorLogoOnly}
                    alt="Passable"
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => setDropdownMenuOpen(false)}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                  >
                    <span className="sr-only">Close menu</span>

                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {getCoreScreens(userPermissions).map((navItem) => (
                  <Link
                    key={navItem.path}
                    to={navItem.path}
                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                  >
                    {navItem.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className="pt-4 pb-2">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://icon-library.com/images/default-profile-icon/default-profile-icon-16.jpg"
                    alt=""
                  />
                </div>
                <div className="ml-3 min-w-0 flex-1">
                  <div className="text-base font-medium text-gray-800 truncate">
                    Rebecca Nicholas
                  </div>
                  <div className="text-sm font-medium text-gray-500 truncate">
                    rebecca.nicholas @example.com
                  </div>
                </div>
                <button className="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                  <span className="sr-only">View notifications</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {/* <a
                  href="/"
                  className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                >
                  Your Profile
                </a> */}
                {/* <a
                  href="/"
                  className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                >
                  Settings
                </a> */}
                <button
                  onClick={handleSignOut}
                  className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

const TopNavBarRight = ({ dropdownMenuOpen, setDropdownMenuOpen }: any) => {
  return (
    <div className="hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5">
      <button
        type="button"
        className="flex-shrink-0 p-1 text-cyan-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
      >
        <span className="sr-only">View notifications</span>

        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </button>

      <div
        className="ml-4 relative flex-shrink-0"
        onBlur={() => setDropdownMenuOpen(false)}
      >
        <div>
          <button
            onClick={() => setDropdownMenuOpen(true)}
            type="button"
            className="bg-white rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100"
            id="user-menu"
            aria-haspopup="true"
          >
            <span className="sr-only">Open user menu</span>
            <img
              className="h-8 w-8 rounded-full"
              src="https://icon-library.com/images/default-profile-icon/default-profile-icon-16.jpg"
              alt=""
            />
          </button>
        </div>

        <Transition
          show={dropdownMenuOpen}
          enter=""
          enterFrom=""
          enterTo=""
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {/* <a
              href="/"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              Your Profile
            </a> */}
            {/* <a
              href="/"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              Settings
            </a> */}
            <button
              onClick={handleSignOut}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
            >
              Sign out
            </button>
          </div>
        </Transition>
      </div>
    </div>
  );
};
const TopNavBarLeft = () => {
  return (
    <div className="absolute left-0 py-5 flex-shrink-0 lg:static">
      <IonRouterLink href="/">
        <span className="sr-only">Passable</span>

        <img src={WhiteLogoOnly} className="h-12 w-auto" alt="Passable Logo" />
      </IonRouterLink>
    </div>
  );
};
