const passColorMapping: {
  [char: string]: { radialGradient: string; color: string };
} = {
  lightBlue: {
    radialGradient:
      "radial-gradient(circle at 98% 97%, rgb(24, 147, 233), rgb(5, 181, 222))",
    color: "rgb(19, 155, 230)",
  },
  red: {
    radialGradient:
      "radial-gradient(circle at 98% 97%, rgb(245, 43, 79), rgb(243, 116, 38))",
    color: "rgb(245, 61, 69)",
  },
  pink: {
    radialGradient:
      "radial-gradient(circle at 98% 97%, rgb(210, 44, 159), rgb(244, 96, 149))",
    color: "rgb(218, 56, 157)",
  },
};

export default passColorMapping;
