import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "emoji-mart/css/emoji-mart.css";
import { gql, useQuery } from "@apollo/client";

import StudentList from "./StudentsList";
import { useTypedSelector } from "../App/rootReducer";
import { Student } from "../types/school";
import RoomGrid from "./RoomGrid";
import StudentViewer from "./StudentViewer";
import RoomViewer from "./RoomViewer";

const SEARCH_QUERY = gql`
  query SearchSchoolRoomsAndStudents(
    $school_id: uuid = ""
    $name: String = ""
  ) {
    schools(where: { id: { _eq: $school_id } }) {
      users(
        limit: 5
        where: {
          role: { _eq: "student" }
          user: { full_name: { _ilike: $name } }
        }
      ) {
        user {
          id
          full_name
        }
      }
      rooms(limit: 5, where: { name: { _ilike: $name } }) {
        name
        pinnable {
          name
          color
          emoji
          rooms {
            id
            name
          }
        }
        room_number
        time_limit
        travel_type
      }
    }
  }
`;

const SearchStep = ({
  setSearchName,
  searchName,
}: {
  setSearchName: any;
  searchName: any;
}) => {
  return (
    <div>
      {/* <label className="block text-sm font-medium text-gray-700">
        Type in a student's name
      </label> */}

      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {/* Heroicon name: solid/users */}
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
            </svg>
          </div>
          <input
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            type="text"
            name="student-name"
            id="student-name"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
            placeholder="Search for a student or a room"
          />
        </div>
        <button className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          {/* Heroicon name: solid/sort-ascending */}
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
          </svg>
          <span>Sort</span>
        </button>
      </div>
    </div>
  );
};

const ResultsDisplay = ({
  users,
  rooms,
  handleStudentSelect,
  handlePinnableSelect,
}: {
  users: any;
  rooms: any;
  handleStudentSelect: any;
  handlePinnableSelect: any;
}) => {
  const pinnables = rooms?.map((room: any) => room?.pinnable);

  return (
    <>
      <h3 className="pt-6 font-bold">Students</h3>
      <StudentList onStudentSelect={handleStudentSelect} students={users} />
      <h3 className="pt-6 font-bold">Rooms</h3>
      <RoomGrid
        customData={pinnables}
        handlePinnableSelect={handlePinnableSelect}
      />
    </>
  );
};

const SearchModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: any;
}) => {
  // Selectors
  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );

  // Internal modal state
  const [selectedStudent, setSelectedStudent] = useState<Student>();
  const [selectedRoom, setSelectedRoom] = useState<Student>();
  const [activeStep, setActiveStep] = useState("search");

  const [searchName, setSearchName] = useState("");
  // TODO: Add debouncing to search

  const searchTest = `%${searchName}%`;

  const { loading, error, data: searchData } = useQuery(SEARCH_QUERY, {
    variables: { school_id: currentSchool?.id, name: searchTest },
  });

  const rooms = searchData?.schools[0]?.rooms;
  const users = searchData?.schools[0]?.users;

  console.log("Rooms", rooms);
  console.log("Users", users);

  return (
    <>
      {/* Actual Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent className="rounded-t-xl">
          <ModalHeader
            style={{
              // backgroundImage: passColorMapping["lightBlue"].radialGradient,
              transition: "background-image .3s ease",
            }}
            className=" rounded-t-xl shadow-md"
          >
            <Button
              disabled={activeStep === "search"}
              mr="5"
              onClick={() => setActiveStep("search")}
            >
              Back
            </Button>
            Search
          </ModalHeader>
          <ModalBody pt={8} pb={6}>
            {error && <h1>Error: {error.message}</h1>}
            {activeStep === "search" && (
              <SearchStep
                setSearchName={setSearchName}
                searchName={searchName}
              />
            )}

            {activeStep === "viewStudent" && (
              <StudentViewer studentData={selectedStudent} />
            )}

            {activeStep === "viewRoom" && (
              <RoomViewer roomData={selectedRoom} />
            )}
            {loading && <Spinner style={{ marginTop: "20px" }} />}

            {activeStep === "search" && (
              <ResultsDisplay
                handleStudentSelect={(studentData: any) => {
                  setActiveStep("viewStudent");
                  setSelectedStudent(studentData);
                }}
                handlePinnableSelect={(pinnableData: any) => {
                  setActiveStep("viewRoom");
                  setSelectedRoom(pinnableData.rooms[0]); // TODO: Support for folders
                }}
                users={users}
                rooms={rooms}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SearchModal;
