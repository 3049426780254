import React from "react";

const StudentListItem = ({
  student,
  onStudentSelect,
}: {
  student: any;
  onStudentSelect: any;
}) => {
  const studentData = { fullName: student?.full_name, id: student?.id };

  return (
    <li className="py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <img
            className="h-8 w-8 rounded-full"
            src="https://static.thenounproject.com/png/630740-200.png"
            alt=""
          />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">
            {studentData.fullName}
          </p>
        </div>
        <div>
          <button
            onClick={() => onStudentSelect(studentData)}
            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
          >
            Select Student
          </button>
        </div>
      </div>
    </li>
  );
};

const StudentList = ({
  students,
  onStudentSelect,
}: {
  students: any[];
  onStudentSelect: any;
}) => {
  return (
    <div>
      <div className="flow-root mt-6">
        <ul className="-my-5 divide-y divide-gray-200">
          {students?.map((studentInfo) => (
            <StudentListItem
              onStudentSelect={onStudentSelect}
              student={studentInfo?.user}
            />
          ))}
        </ul>

        {(!students || students?.length === 0) && (
          <h2>No matching students.</h2>
        )}
      </div>
      {/* <div className="mt-6">
        <a
          href="/"
          className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          View more matches
        </a>
      </div> */}
    </div>
  );
};

export default StudentList;
