import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { Offline } from "react-detect-offline";
// import Drift from "react-driftjs";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import "../theme/variables.css";
import "../styles/main.css";
import { getCoreScreens } from "../config/navConfig";
import Login from "../pages/Login/index";
import AppAuthentication from "./AppAuthentication";
import {
  HttpLink,
  split,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition, Observable } from "@apollo/client/utilities";
import { useSelector } from "react-redux";
import { RootState, useTypedSelector } from "./rootReducer";
import ProfileManager from "./ProfileManager";
import ProtectedRoute from "../components/ProtectedRoute";
import { onError } from "@apollo/client/link/error";
import history from "../utils/history";

const App: React.FC = () => {
  // Apollo Setup
  const authState = useSelector((state: RootState) => state.auth);

  const isSignedInWithProfile =
    authState.status === "in" && authState.retrievedProfile;
  const headers = { Authorization: `Bearer ${authState?.token}` };

  const httpLink = new HttpLink({
    uri: "https://apt-chamois-59.hasura.app/v1/graphql",
    headers,
  });

  const wsLink = new WebSocketLink({
    uri: "wss://apt-chamois-59.hasura.app/v1/graphql",
    options: {
      reconnect: true,
      connectionParams: {
        headers,
      },
    },
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          console.log(`[GraphQL error]: Message: ${message}`);
          return Observable.of(operation);
        });
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError.message}`);
        return Observable.of(operation);
      }
      return forward(operation);
    }
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      console.log(headers);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: errorLink.concat(splitLink),
    cache: new InMemoryCache(),
  });

  // Fallback if we need this to be initialized
  // if (!authState?.uid) {
  //   return <h1>Loading...</h1>
  // }

  const userPermissions = useTypedSelector(
    (state: RootState) => state.auth.profile.permissions
  );
  console.log("Activated Permissions:", userPermissions);

  return (
    <ApolloProvider client={client}>
      <AppAuthentication />
      <ProfileManager />
      <IonApp style={{ width: "100vw", height: "100vh" }}>
        {/* <Drift appId="hepw2zgbbuk4" /> */}

        <Offline>
          You are currently offline. Passable requires an active network
          connection.
        </Offline>

        <IonReactRouter history={history}>
          <IonTabs>
            <IonRouterOutlet>
              <Route path="/login" component={Login} exact />

              {getCoreScreens(userPermissions).map((navItem) => (
                <ProtectedRoute
                  key={navItem.path}
                  loggedIn={isSignedInWithProfile}
                  path={navItem.path}
                  component={navItem.component}
                  exact
                />
              ))}

              {isSignedInWithProfile ? (
                <Route render={() => <Redirect to="/home" />} />
              ) : (
                <Route render={() => <Redirect to="/login" />} />
              )}
            </IonRouterOutlet>
            {
              <IonTabBar slot="bottom" className="">
                {getCoreScreens(userPermissions).map((navItem) => (
                  <IonTabButton
                    key={navItem.path}
                    tab={navItem.title}
                    href={navItem.path}
                  >
                    <IonIcon icon={navItem.icon} />
                    <IonLabel>{navItem.title}</IonLabel>
                  </IonTabButton>
                ))}
              </IonTabBar>
            }
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </ApolloProvider>
  );
};

export default App;
