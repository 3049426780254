import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { isPlatform } from "@ionic/react";

import HallPassRequests from "./HallPassRequests";
// import ActionsPanel from "./ActionsPanel";
import Header from "./Header";
import WelcomePanel from "./WelcomePanel";
import PassGrid from "../../components/PassGrid";
import toast from "react-hot-toast";

const HomePassesGrid = () => (
  <section aria-labelledby="announcements-title">
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <div className="p-6">
        <h2
          className="text-base font-medium text-gray-900"
          id="announcements-title"
        >
          Your Active Passes
        </h2>
        <div className="flow-root mt-6">
          <ul className="-my-5 divide-y divide-gray-200">
            <li className="py-5">
              <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                <PassGrid mode="activePasses" showStudentName />
              </div>
            </li>
          </ul>
        </div>
        <div className="mt-6">
          <button
            onClick={() =>
              toast.error(
                "Announcements have not been enabled for your district yet. Please try again later."
              )
            }
            className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            View Past Passes
          </button>
        </div>
      </div>
    </div>
  </section>
);

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        {isPlatform("mobile") && (
          <IonHeader collapse="condense" translucent>
            <IonToolbar>
              <IonTitle>Home</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="h-full bg-gray-100">
          <Header />
          <main className="-mt-24 pb-8">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 className="sr-only">Profile</h1>
              {/* Main 3 column grid */}
              <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                {/* Left column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                  <WelcomePanel />
                  <HomePassesGrid />
                  {/* <ActionsPanel /> */}
                </div>

                {/* Right column */}
                <div className="grid grid-cols-1 gap-4">
                  {/* <Announcements /> */}
                  <HallPassRequests />
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </IonContent>
    </IonPage>
  );
};

const Footer = () => {
  return (
    <footer>
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
          <span className="block sm:inline">
            &copy; 2021 Passable, a Quanta Creative Product |
          </span>{" "}
          <span className="block sm:inline">All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};

export default Home;
