import React, { useState, FormEvent, useEffect } from "react";
import { FaGoogle } from "react-icons/fa";
import { AiFillCloud } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { ColorWordmarkLogo } from "../../config/images";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../../App/rootReducer";
import { auth } from "../../App/AppAuthentication";
import { IonPage } from "@ionic/react";

const Login = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const authState = useSelector((state: RootState) => state?.auth);

  const history = useHistory();

  const handleEmailAndPasswordLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    toast.promise(
      auth
        .signInWithEmailAndPassword(emailAddress, password)
        .catch((error: any) => {
          // Handle Errors here.
          const errorMessage = error.message;
          toast.error(errorMessage);
        }),
      {
        loading: "Signing in...",
        success: <b>Signed in!</b>,
        error: <b>Something went wrong with signing in.</b>,
      }
    );

    setLoading(false);
  };

  useEffect(() => {
    if (authState.status === "in" && authState.retrievedProfile)
      history.push("/home");
    if (authState.status === "in")
      toast("Signed in... Waiting for profile data...", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    // eslint-disable-next-line
  }, [authState.status, authState.retrievedProfile]);

  return (
    <IonPage>
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto"
                src={ColorWordmarkLogo}
                alt="Passable"
              />
              <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                Sign in to Passable
              </h2>
              <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                Or{" "}
                <a
                  href="/"
                  className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  get started with Passable at your school
                </a>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div>
                  <p className="text-sm leading-5 font-medium text-gray-700">
                    Sign in with
                  </p>

                  <div className="mt-1 grid grid-cols-2 gap-3">
                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          aria-label="Sign in with Google (GSuite)"
                        >
                          <FaGoogle className="w-5 h-5" />
                        </button>
                      </span>
                    </div>

                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          aria-label="Sign in with Twitter"
                        >
                          <AiFillCloud className="w-5 h-5" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm leading-5">
                    <span className="px-2 bg-white text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form
                  onSubmit={handleEmailAndPasswordLogin}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        type="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember_me"
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                      />
                      <label
                        htmlFor="remember_me"
                        className="ml-2 block text-sm leading-5 text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    {/* <div className="text-sm leading-5">
                      <a
                        href="/"
                        className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      >
                        Forgot your password?
                      </a>
                    </div> */}
                  </div>

                  {loading && <SigningInStatus />}
                  {!loading && (
                    <div>
                      <span className="block w-full rounded-md shadow-sm">
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                        >
                          Sign in
                        </button>
                      </span>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1527891751199-7225231a68dd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
            alt="School Background"
          />
        </div>
      </div>
    </IonPage>
  );
};

const SigningInStatus = () => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        {/* Heroicon name: information-circle */}
        <svg
          className="h-5 w-5 text-blue-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm font-bold leading-5 text-blue-700">
          Securely signing you in...
        </p>
      </div>
    </div>
  </div>
);

export default Login;
