import { gql, useQuery } from "@apollo/client";
import { Stack, Skeleton, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTypedSelector } from "../App/rootReducer";
import { Pinnable } from "../types/school";
import passColorMapping from "../utils/passColorMapping";
import { Emoji } from "emoji-mart";
import { motion } from "framer-motion";

const GET_SCHOOL_PINNABLES_AND_ROOMS = gql`
  query GetSchoolPinnablesAndRooms($school_id: uuid) {
    pinnables(where: { school_id: { _eq: $school_id } }) {
      rooms {
        id
        room_number
      }
      name
      id
      emoji
      color
    }
  }
`;

const PinnableCard = ({
  pinnableData,
  handleOnClick,
}: {
  pinnableData: Pinnable;
  handleOnClick: any;
}) => {
  const isSingleRoom = pinnableData.rooms.length === 1;

  const RoomNumber = () => (
    <>
      {isSingleRoom && pinnableData?.rooms[0].room_number && (
        <span className="mr-4 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-white bg-opacity-30 text-white">
          <svg
            className="-ml-0.5 mr-1.5 h-2 w-2"
            style={{
              color: passColorMapping[pinnableData.color].color,
            }}
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          {pinnableData?.rooms[0].room_number}
        </span>
      )}
    </>
  );

  return (
    <motion.button
      onClick={() => handleOnClick(pinnableData)}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.95 }}
      className="bg-white overflow-hidden shadow rounded-lg"
      style={{
        maxWidth: "140px",
        minWidth: "140px",
        minHeight: "75px",
        maxHeight: "75px",
        margin: "5px",
        backgroundImage: passColorMapping[pinnableData.color].radialGradient,
      }}
    >
      <Flex direction="column">
        <Flex justifyContent="center">
          <RoomNumber />
          <Emoji emoji={pinnableData?.emoji} size={22} set="twitter" />
        </Flex>

        <Text
          mt="1"
          color="white"
          fontSize="sm"
          fontWeight="semibold"
          className="leading-4"
        >
          {pinnableData.name}
        </Text>
      </Flex>
    </motion.button>
  );
};

const RoomGrid = ({
  handlePinnableSelect,
  customData,
}: {
  handlePinnableSelect?: any;
  customData?: any;
}) => {
  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );

  const { loading, error, data: roomData } = useQuery(
    GET_SCHOOL_PINNABLES_AND_ROOMS,
    {
      variables: { school_id: currentSchool.id },
    }
  );

  const pinnables = customData || roomData?.pinnables;

  if (loading)
    return (
      <>
        <Stack>
          <Skeleton height="80px" />
          <Skeleton height="80px" />
        </Stack>
      </>
    );

  if (error) return <h1>Error: {error.message}</h1>;

  return (
    <>
      <Flex
        className="mt-2"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        {/* Card */}
        {pinnables.map((pinnableData: Pinnable) => (
          <PinnableCard
            handleOnClick={handlePinnableSelect}
            pinnableData={pinnableData}
          />
        ))}

        {/* More items... */}
      </Flex>
    </>
  );
};

export default RoomGrid;
