import React from "react";
import RoomGrid from "../../../components/RoomGrid";
import { Text } from "@chakra-ui/react";

const FromRoomSelector = ({
  handleFromRoomSelect,
}: {
  handleFromRoomSelect: any;
}) => {
  return (
    <>
      <div className="bg-gray-50 overflow-hidden rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <Text> You do not have any favorites set... </Text>
        </div>
      </div>
      <RoomGrid handlePinnableSelect={handleFromRoomSelect} />
    </>
  );
};

export default FromRoomSelector;
