import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Text,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import { Emoji } from "emoji-mart";
import React from "react";
import CapacityTracker from "../../../components/CapacityTracker";
import { Pinnable, Student } from "../../../types/school";
import passColorMapping from "../../../utils/passColorMapping";

const PassOptionsAndDispatch = ({
  travelType,
  setTravelType,
  setPassDuration,
  passDuration,
  selectedToRoomPinnable,
  handlePassDispatch,
  selectedStudent,
}: {
  travelType: string;
  setTravelType: any;
  setPassDuration: any;
  passDuration: number;
  selectedToRoomPinnable: Pinnable | undefined;
  handlePassDispatch: any;
  selectedStudent: Student | undefined;
}) => {
  if (!selectedToRoomPinnable)
    return (
      <>
        <Flex direction="column">
          <Spinner />
          <Text>
            A destination room was not selected. Attempting to recover from this
            issue... Refresh the page after some time if nothing changes.
          </Text>
        </Flex>
      </>
    );

  if (!selectedStudent)
    return (
      <>
        <Flex direction="column">
          <Spinner />
          <Text>
            A student was not selected. Attempting to recover from this issue...
            Refresh the page after some time if nothing changes.
          </Text>
        </Flex>
      </>
    );

  return (
    <>
      <div
        className="rounded-lg shadow rounded-lg divide-y-2 divide-gray-200 divide-opacity-60 mx-8"
        style={{
          background:
            passColorMapping[selectedToRoomPinnable.color].radialGradient,
        }}
      >
        <div className="px-4 py-6 sm:px-6">
          <Flex alignItems="center">
            <Emoji
              emoji={selectedToRoomPinnable?.emoji}
              size={32}
              set="twitter"
            />
            <Text fontSize="xl" fontWeight="bold" color="white" px="20px">
              {selectedToRoomPinnable.name}
            </Text>
          </Flex>
        </div>
        <div className="px-4 sm:p-6">
          <Flex direction="row" my="10" justifyContent="center">
            <Flex direction="row">
              <Slider
                colorScheme="whiteAlpha"
                onChange={(value) => setPassDuration(value)}
                aria-label="slider-ex-3"
                defaultValue={15}
                orientation="vertical"
                minH="52"
                min={1}
                max={20}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>

              <Flex direction="column" pl="20px">
                <div className="mb-4">
                  <CapacityTracker
                    roomId={selectedToRoomPinnable?.rooms[0]?.id}
                  />
                </div>
                <Text fontSize="lg" color="white">
                  How long should the pass be for?
                </Text>
                <Text fontSize="3xl" fontWeight="bold" color="white">
                  {passDuration} minutes
                </Text>
                <IonSegment
                  className="mt-4"
                  mode="ios"
                  onIonChange={(e) => setTravelType(e.detail.value)}
                  value={travelType}
                >
                  <IonSegmentButton value="one-way">
                    <IonLabel
                      color={travelType === "one-way" ? "dark" : "light"}
                    >
                      One Way
                    </IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="round-trip">
                    <IonLabel
                      color={travelType === "round-trip" ? "dark" : "light"}
                    >
                      Round Trip
                    </IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </Flex>
            </Flex>
          </Flex>
          <Button
            className="shadow w-full h-20 bg-opacity-30 bg-white rounded-xl text-white font-bold"
            onClick={handlePassDispatch}
          >
            Send Pass
          </Button>
        </div>
        <div className="px-4 py-4 sm:px-6 bg-white bg-opacity-20">
          <Flex alignItems="center">
            <Text fontSize="md" color="white" px="20px">
              Pass being sent to:{" "}
              <Text fontWeight="bold">{selectedStudent.fullName}</Text>
            </Text>
          </Flex>
        </div>
      </div>
    </>
  );
};

export default PassOptionsAndDispatch;
