import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import { Spinner } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useTypedSelector } from "../../../App/rootReducer";
import StudentList from "../../../components/StudentsList";

const STUDENT_SEARCH_QUERY = gql`
  query SearchStudentsInSchool($school_id: uuid = "", $name: String = "") {
    schools(where: { id: { _eq: $school_id } }) {
      users(
        limit: 10
        where: {
          role: { _eq: "student" }
          user: { full_name: { _ilike: $name } }
        }
      ) {
        user {
          id
          full_name
        }
      }
    }
  }
`;

const StudentSelector = ({
  handleStudentSelect,
}: {
  handleStudentSelect: any;
}) => {
  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );

  const [searchName, setSearchName] = useState("");
  // TODO: Add debouncing to search

  const searchTest = `%${searchName}%`;

  const { loading, error, data: studentSearchData, refetch } = useQuery(
    STUDENT_SEARCH_QUERY,
    {
      variables: { school_id: currentSchool.id, name: searchTest },
    }
  );

  return (
    <>
      {/* Search Bar */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Type in a student's name
        </label>

        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {/* Heroicon name: solid/users */}
              <svg
                className="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
            </div>
            <input
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              type="text"
              name="student-name"
              id="student-name"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
              placeholder="John Doe"
            />
          </div>
          <button
            onClick={() => {
              refetch();
            }}
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            {/* Heroicon name: solid/sort-ascending */}
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
            </svg>
            <span>Sort</span>
          </button>
        </div>
      </div>

      {loading && <Spinner style={{ marginTop: "20px" }} />}
      {error && <h1>Error: {error.message}</h1>}

      <StudentList
        onStudentSelect={handleStudentSelect}
        students={studentSearchData?.schools[0]?.users}
      />
    </>
  );
};

export default StudentSelector;
