import Home from "../pages/Home/index";
import Accounts from "../pages/Accounts/index";
import Rooms from "../pages/Rooms/index";
import HallMonitor from "../pages/HallMonitor/index";

import { home, walk, key } from "ionicons/icons";

const navConfig = [
  {
    permission: "base_permissions",
    title: "Home",
    path: "/home",
    component: Home,
    icon: home,
  },
  {
    permission: "base_permissions",
    title: "Hall Monitor",
    path: "/hall_monitor",
    component: HallMonitor,
    icon: walk,
  },
  {
    permission: "create_accounts",
    title: "Accounts",
    path: "/accounts",
    component: Accounts,
    icon: key,
  },
  {
    permission: "create_rooms",
    title: "Rooms",
    path: "/rooms",
    component: Rooms,
    icon: home,
  },
];

export const getCoreScreens = (userPermissions: any) =>
  navConfig.filter((screen) => userPermissions.includes(screen.permission));

export default navConfig;
