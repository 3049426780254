import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { EmojiData, Picker } from "emoji-mart";
import React, { useState } from "react";
import passColorMapping from "../../utils/passColorMapping";
import "emoji-mart/css/emoji-mart.css";
import { gql, useMutation } from "@apollo/client";
import { useTypedSelector } from "../../App/rootReducer";
import toast from "react-hot-toast";

const CREATE_PINNABLE_AND_ASSOCIATED_ROOM = gql`
  mutation CreatePinnableAndAssociatedRoom(
    $name: String
    $room_number: String
    $time_limit: Int
    $now_request_mode: String
    $travel_type: String
    $school_id: uuid
    $emoji: String
    $color: String
    $max_passes_to: Int
  ) {
    insert_pinnables_one(
      object: {
        rooms: {
          data: {
            name: $name
            now_approval_required: false
            time_limit: $time_limit
            now_request_mode: $now_request_mode
            travel_type: $travel_type
            school_id: $school_id
            now_request_send_destination_teachers: true
            now_request_send_origin_teachers: true
            max_passes_to: $max_passes_to
            room_number: $room_number
          }
        }
        emoji: $emoji
        color: $color
        name: $name
        school_id: $school_id
      }
    ) {
      id
      rooms {
        id
      }
    }
  }
`;

const ColorSelector = ({
  selectedColor,
  setSelectedColor,
}: {
  selectedColor: string;
  setSelectedColor: any;
}) => {
  return (
    <>
      <div className="flex flex-row">
        {Object.keys(passColorMapping).map((key, index) => {
          const { color } = passColorMapping[key];
          return (
            <div
              key={key}
              onClick={() => setSelectedColor(key)}
              style={{
                width: "28px",
                height: "28px",
                borderRadius: "50%",
                display: "flex",
                margin: "4px",
                cursor: "pointer",
                border: "3px solid #fff",
                transition: "box-shadow .3s ease",

                boxShadow:
                  selectedColor === key
                    ? color + "0px 0px 20px"
                    : "rgb(0 0 0 / 30%) 0px 2px 4px 1px",
                background: color,
                transform: "scale(1)",
              }}
            ></div>
          );
        })}
      </div>
    </>
  );
};

const CreateRoomModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [roomName, setRoomName] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [timeLimit, setTimeLimit] = useState(15);
  const [passLimit, setPassLimit] = useState(10);
  const [travelType, setTravelType] = useState("round-trip");
  const [selectedColor, setSelectedColor] = useState("lightBlue");
  const [emoji, setEmoji] = useState<EmojiData>();
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const currentSchool = useTypedSelector(
    (state) => state.auth.profile.currentSchool
  );

  const [
    createPinnableAndRoom,
    {
      loading: createPinnableAndRoomLoading,
      error: createPinnableAndRoomError,
    },
  ] = useMutation(CREATE_PINNABLE_AND_ASSOCIATED_ROOM);

  const handleRoomCreate = async () => {
    if (!emoji) return toast.error("Please select an emoji.");
    if (roomName === "") return toast.error("Please type a room name.");
    if (roomNumber === "") return toast.error("Please type a room number.");

    try {
      await createPinnableAndRoom({
        variables: {
          name: roomName,
          now_request_mode: "any_teacher", //TODO: Support more modes
          travel_type: travelType,
          school_id: currentSchool.id,
          emoji: emoji?.id,
          max_passes_to: passLimit,
          time_limit: timeLimit,
          color: selectedColor,
          room_number: roomNumber,
        },
      });

      toast.success("Successfully created room: " + roomName);
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        type="button"
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
      >
        Add Rooms
      </button>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent className="rounded-xl">
          <ModalHeader
            style={{
              backgroundImage: passColorMapping[selectedColor].radialGradient,
              transition: "background-image .3s ease",
            }}
            className="text-white rounded-xl shadow-sm"
          >
            Create room
          </ModalHeader>
          {/* <ModalCloseButton color="white" /> */}
          <ModalBody pt={8} pb={6}>
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Appearance Options
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Adjust how the room will appear for your users in
                      selection menus.
                    </p>
                  </div>

                  <h3 className="text-md font-medium leading-6 text-gray-900 pt-3">
                    Color
                  </h3>
                  <ColorSelector
                    setSelectedColor={setSelectedColor}
                    selectedColor={selectedColor}
                  />

                  <h3 className="text-md font-medium leading-6 text-gray-900 pt-3">
                    Emoji
                  </h3>

                  <Popover
                    isOpen={emojiPickerOpen}
                    onClose={() => setEmojiPickerOpen(false)}
                  >
                    <PopoverTrigger>
                      <Button onClick={() => setEmojiPickerOpen(true)}>
                        {emoji?.name || "Select Emoji"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      {/* <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Confirmation!</PopoverHeader>
                      <PopoverBody> */}
                      <Picker
                        set="twitter"
                        onSelect={(e) => {
                          setEmoji(e);
                          setEmojiPickerOpen(false);
                        }}
                      />
                      {/* </PopoverBody> */}
                    </PopoverContent>
                  </Popover>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST">
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 pb-6 bg-white sm:p-6">
                        <div className="px-4 sm:px-0">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Room Settings
                          </h3>
                          {/* <p className="mt-1 text-sm text-gray-600">
                            Adjust 
                          </p> */}
                        </div>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first_name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Room Name
                            </label>
                            <input
                              value={roomName}
                              onChange={(e) => setRoomName(e.target.value)}
                              type="text"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="room_number"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Room Number
                            </label>
                            <input
                              value={roomNumber}
                              onChange={(e) => setRoomNumber(e.target.value)}
                              type="text"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-4">
                            <label
                              htmlFor="email_address"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email address
                            </label>
                            <input
                              type="text"
                              name="email_address"
                              id="email_address"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-8 sm:col-span-3">
                            <label
                              htmlFor="country"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Travel Type
                            </label>
                            <RadioGroup
                              onChange={setTravelType as any}
                              value={travelType}
                            >
                              <Stack direction="row">
                                <Radio value="round-trip">Round Trip</Radio>
                                <Radio value="one-way">One Way</Radio>
                                <Radio value="both">Both</Radio>
                              </Stack>
                            </RadioGroup>
                            {/* <select
                              id="country"
                              name="country"
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option>United States</option>
                              <option>Canada</option>
                              <option>Mexico</option>
                            </select> */}
                          </div>

                          <div className="col-span-4 sm:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Time Limit
                            </label>

                            <NumberInput
                              step={5}
                              defaultValue={10}
                              min={1}
                              max={500}
                              onChange={(e) => setTimeLimit(parseInt(e))}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </div>

                          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                            <label
                              htmlFor="city"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Pass Limit (Leave blank for infinite)
                            </label>

                            <NumberInput
                              step={1}
                              defaultValue={10}
                              min={1}
                              max={500}
                              onChange={(e) => setPassLimit(parseInt(e))}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </div>

                          {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label
                              htmlFor="state"
                              className="block text-sm font-medium text-gray-700"
                            >
                              State / Province
                            </label>
                            <input
                              type="text"
                              name="state"
                              id="state"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label
                              htmlFor="postal_code"
                              className="block text-sm font-medium text-gray-700"
                            >
                              ZIP / Postal
                            </label>
                            <input
                              type="text"
                              name="postal_code"
                              id="postal_code"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Save
                        </button>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <h1>{JSON.stringify(createPinnableAndRoomError)}</h1>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleRoomCreate}
              loading={createPinnableAndRoomLoading}
            >
              Create Room
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateRoomModal;
